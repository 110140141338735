// 自定义公式 - 相关接口
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 校验自定义公式
export async function checkFormula(params) {
  return request(`${WAGE}/salaryItem/checkFormula`, METHOD.POST, params)
}

// 查询指标项及分类
export async function getAll(params) {
  return request(`${WAGE}/indexItem/getAll`, METHOD.GET, params)
}

// 添加自定义指标
export async function addCustom(params) {
  return request(`${WAGE}/indexItem/addCustom`, METHOD.POST, params)
}

// 自定义公式测算
export async function formulaCalc(params) {
  return request(`${WAGE}/formulaCalc/custom`, METHOD.POST, params)
}
